import React from 'react';
import { Link } from 'gatsby';

import RightArrow from '../../assets/images/right-arrow-icon.svg';

import HandWithPill from '../../assets/images/prescribed-icon.svg';
import DollarIcon from '../../assets/images/dollar-icon-home.svg';

const MeetMyfembree = () => {
  return (
    <section className="meet-myfembree overflow-hidden bg-fuchsia-010">
      <div className="container mx-auto">
        <h1 className="text-center mx-auto font-mulish font-black text-purple-100">
          Relief is possible with Myfembree
        </h1>

        <p className="text-purple-100 font-semibold text-center">
          Choose a condition to discover symptom relief with Myfembree.
        </p>

        <div className="links-container flex justify-center">
          <Link to="uterine-fibroids" className="text-white-100 font-mulish uf">
            <div>
              <span className="font-black text-center">UTERINE FIBROIDS</span>

              <span className="font-semibold text-center">
                Heavy period bleeding from uterine fibroids in premenopausal
                women
              </span>
            </div>

            <img src={RightArrow} alt="" width={31} height={50} />
          </Link>

          <Link to="endometriosis" className="text-white-100 font-mulish em">
            <div>
              <span className="font-black text-center">ENDOMETRIOSIS</span>

              <span className="font-semibold text-center">
                Moderate to severe endometriosis pain in premenopausal women
              </span>
            </div>

            <img src={RightArrow} alt="" width={31} height={50} />
          </Link>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="purple-container text-white-100 flex mx-auto">
          <article className="flex flex-col">
            <div className="flex flex-col-reverse lg:flex-col">
              <img src={HandWithPill} alt="" width={106} height={73} />

              <h2 className="font-black">Already prescribed Myfembree?</h2>
            </div>

            <p className="mx-auto">
              Great! You may already be on the path to relief. Learn how to
              correctly take Myfembree.
            </p>

            <Link className="white-btn block mx-auto	" to="starting-myfembree">
              HOW TO TAKE MYFEMBREE
            </Link>
          </article>

          <article className="">
            <div className="flex flex-col-reverse lg:flex-col">
              <img src={DollarIcon} alt="" width={121} height={120} />

              <h2 className="font-black">Cost & Savings</h2>
            </div>

            <p className="text-center mx-auto">
              The Myfembree Copay Assistance Program may help commercially
              insured people with the cost of treatment. You could pay as little
              as $5 per prescription (up to a maximum of $5,000 per calendar
              year).
            </p>

            <p className="additional-link mx-auto">
              Additional{' '}
              <Link className="" to="cost-and-support/#terms-and-conditions ">
                Terms and Conditions
              </Link>{' '}
              apply.
            </p>

            <Link className="white-btn block mx-auto" to="cost-and-support">
              COST AND COPAY SAVINGS
            </Link>
          </article>
        </div>
      </div>

      <small className="block text-center">
        Not actual patients throughout
      </small>
    </section>
  );
};

export default MeetMyfembree;

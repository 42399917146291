import React from 'react';
import CostSupport, {
  costSupport,
  haveQuestions
} from '../CostSupport/CostSupport';
import Layout from '../Layout';
import ReadyToStart from '../ReadyToStart/ReadyToStart';
import MeetMyfembree from '../MeetMyfembree/MeetMyfembree';
// import March from '../March/march';

const HomeContent = () => {
  return (
    <Layout section="main" page="/">
      <>
        <MeetMyfembree />
        {/* <ReadyToStart /> */}
        {/* <March /> */}
        {/* <CostSupport {...costSupport} bodyWrapperClasses="justify-center" /> */}
        {/* <CostSupport {...haveQuestions} /> */}
      </>
    </Layout>
  );
};

export default HomeContent;

import React from 'react';

import HomeContent from '../components/HomeContent/HomeContent';

// As you probably know, Gatsby makes it very easy to route the project.  You simply need to add the .js or .jsx file
// To the pages directory and then the name of the page becomes the route.  However, in order to organize the project
// Properly, you would typically put the .jsx file and the accompanying .scss file into its own directory.  Doing
// That for the pages, would create a double directory for the route.  For example, /home/home.  As a result,
// For organization purposes, I have placed the pages here and simply returned the accompanying, "Content" jsx.
// The content jsx and scss are organized as they're supposed to be, within their own directory and that is the
// Where the content for each page really begins.

const Home = () => {
  return <HomeContent />;
};

export const Head = () => {
  const canonicalURL = 'https://www.myfembree.com/';
  const title =
    'MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) Patient Website';
  const pageTitle =
    'MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) Patient Website';
  const description =
    'MYFEMBREE® can help reduce heavy period bleeding from UF & moderate to severe endo pain in premenopausal women. See safety info & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;
